<template>
  <div>
    <!-- 购买会员弹窗 -->
    <el-dialog custom-class="no-title-dialog1" :visible.sync="isDialogShow" :before-close="closeDialog" width="800px"
      center @open="handleOpen" :close-on-click-modal="false">
      <div class="title">
        <img :src="getTheme == 'light'
          ? require('@/assets/images/members/title.png')
          : require('@/assets/images/members/title2.png')
          " alt />
      </div>
      <div class="renewal-box">
        <el-row :gutter="20" v-if="showQrCode">
          <el-col :span="8" v-for="(item, index) in memBersList" :key="index">
            <div class="item" v-if="index < 3" :class="{ active: index == active }">
              <img class="icon" v-if="index == 0" src="@/assets/images/members/icon.png" alt />
              <div class="content">
                <p class="time">{{ item.Title }}</p>
                <p class="price">
                  活动价：
                  <span>{{ item.Price }}元</span>
                </p>
                <p class="original-price">￥{{ (item.Price * 3).toFixed(2) }}</p>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" v-else>
          <el-col :span="8" v-for="(item, index) in memBersList" :key="index">
            <div class="item" :class="{ active: index == active }" v-if="index < 3" @click="open(index)">
              <img class="icon" :src="index == 0 ? require('@/assets/images/members/icon.png'):require('@/assets/images/members/mem_icon.png')" alt />

              <div class="content">
                <div class="time">
                  <span>{{ item.Title }}</span>
                  <img v-if="item.GiveDays > 0" src="@/assets/images/members/give.png" alt />
                  <span v-if="item.GiveDays > 0" class="giveDays">{{ item.GiveDays }}天</span>
                </div>
                <p class="price">
                  活动价：
                  <span>{{ item.Price }}元</span>
                </p>
                <p class="original-price">￥{{ (item.Price * 3).toFixed(2) }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-row :gutter="30" class="pay-away">
        <el-col :span="12">
          <a v-if="Ali" href="javascript:;" class="a-pay1 pay" :class="{ active: payWay == 1 }" @click="changePayWay(1)">
            <img src="@/assets/images/members/zfb.png" alt />支付宝支付
          </a>

          <a v-else href="javascript:;" class="a-pay1 pay">
            <img src="@/assets/images/members/zfb.png" alt />支付宝支付
          </a>
        </el-col>
        <el-col :span="12" v-if="WeChat">
          <!-- :class="[showQrCode ? 'active' : '', 'a-pay2 pay']" -->
          <a href="javascript:;" class="a-pay2 pay" :class="{ active: payWay == 2 }" @click="changePayWay(2)">
            <img src="@/assets/images/members/wx.png" alt />微信支付
          </a>
        </el-col>
        <el-col :span="12" v-if="paySwitch">
          <a href="javascript:;" :class="[showQrCode ? 'active' : '', 'a-pay2 pay']" @click="changePayWay(3)">
            <img src="@/assets/images/members/wx.png" alt />微信支付
          </a>
        </el-col>
      </el-row>

      <div v-if="showQrCode && paySwitch" class="qrCodeBox">
        <img v-if="qrCodeUrl" :src="qrCodeUrl" alt />
        <div class="ps">
          <p>请添加客服专人微信进行购买；</p>
          <p>需加收1元手续费</p>
          <el-button class="btn-service" @click="onLineService">联系在线客服购买</el-button>
        </div>
      </div>

      <div v-else>
        <div id="qrCode">
          <p class="ad">早买早享受，玩到就是赚到</p>
          <div class="qrCode-box">
            <canvas id="canvas" ref="canvas"></canvas>
          </div>
        </div>
        <div class="agreement-box">
          <p>
            应付金额：
            <span>{{ memberObj.Price }}元</span>
          </p>
          <el-checkbox class="agreement" name="type" v-model="checked" disabled></el-checkbox>
          <a href="javascript:;" @click="linkTo" class="agreement">阅读并同意《会员服务协议》</a>
        </div>
      </div>
    </el-dialog>
    <!-- 禁用黑名单用户提示弹窗 -->
    <BlackListDialog ref="blacklist" :errorMessage="errorMessage" />

    <!-- 支付成功广告弹窗 -->
    <el-dialog custom-class="ad-dialog" :visible.sync="adDialog" width="30%" center v-if="adObj"
      :close-on-click-modal="false">
      <img :src="adObj.ADImageUrl" width="100%"  @click.stop="adToLink(adObj.ADOpenUrl)" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {
  GetMemberPackage,
  CrateOrder,
  GetOrderPayState,
  GetPaymentSwitch,
  GetNavigationList,
} from "@/api";
import { mapGetters } from "vuex";
import QRCode from "qrcode";
import cMethods from "@/util/cMethods.js";
import BlackListDialog from "./BListDialog.vue";

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    slectActive: {
      type: Number,
      default: 0,
    },
  },
  name: "MembersBuy",
  components: {
    BlackListDialog,
  },
  computed: {
    ...mapGetters([
      "isLogin",
      "userInfo",
      "netBarId",
      "netBarName",
      "netBarVIP",
      "getIsAuth",
      "getTheme",
    ]),
  },
  data() {
    return {
      memBersList: [],
      isDialogShow: false,
      memberObj: {},
      payWay: 1, // 支付方式：1.支付宝支付  2.微信支付
      checked: true,
      active: 0,
      qrCode: "",
      timer: null,
      Ali: false,
      WeChat: false,
      qrCodeUrl: "",
      showQrCode: false,
      paySwitch: false,
      errorMessage: "",
      adDialog: false,
      adObj: null
    };
  },
  // created() {
  //   eventBus.$on("membersBuy", (toggle) => {
  //     this.active = toggle;
  //     this.isShow = true;
  //     this._GetMemberPackage();
  //   });
  // },
  mounted() {
    this._GetPaymentSwitch();
  },
  methods: {
    // 在线人工客服
    onLineService() {
      this.$statistic(["B", "客服帮助", "点击", "联系在线客服"]);
      cMethods.OpenIeUrl(
        "http://www.mayidianjing.com/Content/customerService/xiaoneng.html"
      );
    },
    // 获取微信二维码
    async _GetNavigationList() {
      let params = {
        type: 4,
      };
      let res = await GetNavigationList(params);
      if (res.StatusCode == 200) {
        this.qrCodeUrl = res.Object[0].ImgUrl;
      }
    },
    // 获取会员套餐列表
    async _GetPaymentSwitch() {
      let res = await GetPaymentSwitch();
      if (res.StatusCode == 200) {
        this.Ali = res.Object.Ali;
        this.WeChat = res.Object.WeChat;
        this.paySwitch = res.Object.WeChat1;
        if (this.Ali == false) {
          this.payWay = 2;
        }

        if (this.paySwitch) {
          this._GetNavigationList();
        }
      }
    },
    handleOpen() {
      this._GetMemberPackage();
    },
    // 获取会员套餐列表
    async _GetMemberPackage() {
      let res = await GetMemberPackage();
      if (res.StatusCode == 200) {
        this.memBersList = res.Object;
        this.memberObj = this.memBersList[this.active];
        this.$statistic(["A", "游戏详情页", "付款码", "展现量"]);
        if (this.memberObj) {
          this._BuyVipSetMeal();
        }
      }
    },
    // 开通会员弹窗
    open(index) {
      if (this.isLogin) {
        this.active = index;
        this.memberObj = this.memBersList[this.active];
        // 是否绑定手机号
        let phone = sessionStorage.getItem("Phone");
        if (phone) {
          if (this.getIsAuth && !this.userInfo.IDCard) {
            eventBus.$emit("showAuth");
            return false;
          }
          this._BuyVipSetMeal();
        } else {
          eventBus.$emit("showLoginPhone");
        }
      } else {
        eventBus.$emit("getQrCode");
      }
      this.$statistic(["A", "买VIP会员页", "付款码", "展现量"]);
    },
    // 购买会员套餐
    async _BuyVipSetMeal() {
      // 下单获取二维码
      if (this.memberObj) {
        if (this.Ali || this.WeChat) {
          let params = {
            ConsumerId: this.userInfo.ConsumerId,
            MemberPackageId: this.memberObj.Id,
            NetbarId: this.netBarId,
          };
          let res = await CrateOrder(params);

          // 禁用黑名单
          if (res.data == "Error: Request failed with status code 400") {
            this.errorMessage = res.data.response.data.Message;
            this.$refs["blacklist"].blackListDialogShow();
            return;
          }

          // 正常用户
          if (res.StatusCode == 200) {
            if (this.payWay == 1) {
              this.orderCode = res.Object.AliPayUrl;
            }
            if (this.payWay == 2) {
              this.orderCode = res.Object.WeChatPayUrl;
            }
            this.OrderNo = res.Object.OrderNo;
            if (this.orderCode) {
              this.setQrCode(this.orderCode, this.OrderNo);
            }
          }
        }
      }
    },
    // 生成二维码
    async setQrCode(OrderCode, OrderNo) {
      // 显示支付二维码弹窗组件
      let canvas = this.$refs.canvas;
      QRCode.toCanvas(canvas, OrderCode, function (error) {
        if (error) console.error(error);
        // console.log("QRCode success!");
      });
      // 查看订单状态
      clearInterval(this.timer);
      this.timer = setInterval(async () => {
        let params = {
          orderId: OrderNo,
        };

        let res = await GetOrderPayState(params);
        if (res.StatusCode == 200) {
          if (res.Object == 0) {
            this.$store.dispatch("_GetUserInfo");
            // 支付成功弹窗
            if (sessionStorage.getItem('adList')) {
              let adArr = JSON.parse(sessionStorage.getItem('adList'))
              if (adArr) {
                this.adObj = adArr.filter(item => item.ADName === '支付成功广告')[0]
                this.adDialog = true;
              }
            }
            // 关闭弹窗
            this.isDialogShow = false;
            clearInterval(this.timer);
            // 会员支付成功
            if (this.$route.path == "/members") {
              this.$emit("changeShow", "false");
              // this.$router.push("/");
            } else {
              this.$emit("changeShow", "false", "start");
            }
            this.$statistic(["A", "买VIP会员页", "支付成功", "展现量"]);
          }
        }
      }, 3000);
    },
    // 切换支付方式
    changePayWay(index) {
      if (index == 1) {
        this.showQrCode = false;
        this.payWay = index;
        this._BuyVipSetMeal();
      }
      if (index == 2) {
        this.showQrCode = false;
        this.payWay = index;
        this._BuyVipSetMeal();
      }
      if (index == 3) {
        // this.active = 0;
        this.payWay = index;
        this.showQrCode = true;
      }
    },
    // 弹出框关闭后触发
    closeDialog() {
      clearInterval(this.timer);
      this.timer = null;
      this.$emit("changeShow", "false");
    },
    // 广告跳转
    linkTo() {
      cMethods.OpenIeUrl("http://www.mayidianjing.com/Content/html/580tq.html");
    },
    // 登录成功弹窗跳转
    adToLink(url) {
      this.adDialog = false;
      this.$router.push(url)
    }
  },
  watch: {
    isShow(oldVal, newVal) {
      this.isDialogShow = this.isShow;
    },
    slectActive(oldVal, newVal) {
      this.active = this.slectActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-title-dialog1 {
  .title {
    text-align: center;
  }

  .renewal-box {
    padding: 40px 60px;

    .item.active {
      border: 1px solid #c2a36c;
    }

    .item {
      height: 256px;
      @include tag_bg_col();
      position: relative;
      cursor: pointer;
      text-align: center;

      .icon {
        position: absolute;
        top: -7px;
        left: -7px;
      }

      a {
        height: 256px;
        display: block;
        z-index: 9999;
      }

      .time {
        line-height: 25px;
        font-size: 28px;
        @include font_col3();
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 27px;
          height: 28px;
          margin: 0 10px;
        }

        .giveDays {
          font-size: 28px;
          color: #f02828;
        }
      }

      .price {
        font-size: 22px;
        font-weight: 500;
        color: #f02828;
        margin-bottom: 15px;

        span {
          font-size: 24px;
        }
      }

      .original-price {
        text-decoration: line-through;
        font-size: 16px;
        @include font_col9();
      }

      .text {
        height: 44px;
        line-height: 44px;

        img {
          width: 239px;
          height: 44px;
        }
      }
    }

    .item:hover {
      transform: scale(1.03);
      border: 1px solid #c2a36c;
    }

    .content {
      padding: 40px 0 30px 0;
    }
  }

  .renewal-box {
    padding: 20px 0 !important;

    .item {
      height: 166px !important;
      background: #f6f6f6;
      position: relative;
      cursor: pointer;
      text-align: center;

      .content {
        padding: 30px 0 20px 0;

        .time {
          margin-bottom: 20px;
        }
      }
    }

    .item.active {
      background: url("./../../assets/images/members/active.png") no-repeat;
      background-position: right bottom;
    }
  }

  .qrCodeBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    img {
      width: 204px;
      height: 204px;
      margin-right: 20px;
    }

    .ps {
      // padding-top: 20px;
      line-height: 40px;
      font-size: 20px;
      color: #f02828;
    }

    .btn-service {
      margin-top: 20px;
      border: 1px solid $red-col;
      color: $red-col;
    }

    .btn-service:hover {
      color: $white-col;
      background: $red-col;
    }
  }

  .a-pay1,
  .a-pay2 {
    width: 200px;
    height: 40px;
    line-height: 40px;
    display: block;
    text-align: center;
    border: 1px solid #f6f6f6;
    font-size: 16px;
    @include font_col3();
    @include tag_bg_col();

    img {
      margin-right: 10px;
    }
  }

  .a-pay1 {
    float: right;
  }

  .pay.active {
    border: 1px solid #20b0d6;
    background: url("./../../assets/images/members/10.png") no-repeat;
    background-position: right bottom;
  }

  #qrCode {
    text-align: center;
    padding: 10px 0;

    .ad {
      margin-bottom: 10px;
      color: red;
      font-size: 16px;
      // font-weight: bold;
    }

    .qrCode-box {
      width: 164px;
      height: 164px;
      display: inline-block;
      // border: 1px solid #d5d5d5;
      border: 1px solid rgba(155, 155, 156, 0.3);
    }
  }

  .agreement-box {
    text-align: center;
    @include font_col6();

    p {
      font-size: 18px;
      margin-bottom: 10px;

      span {
        font-size: 24px;
        color: #f22826;
      }
    }

    .agreement {
      margin-left: 10px;
      @include font_col6();
    }
  }
}
</style>